import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
          <path d="M2540 3568 c-47 -27 -110 -63 -140 -80 -50 -28 -137 -78 -200 -116
-18 -12 -21 -17 -10 -20 8 -3 54 -29 103 -59 48 -29 90 -53 92 -53 3 0 34 -18
70 -40 36 -22 67 -40 70 -40 3 0 27 -13 53 -30 27 -16 54 -30 61 -30 8 0 11
72 11 260 0 202 -3 260 -12 259 -7 0 -51 -23 -98 -51z"/>
<path d="M2652 2753 l3 -317 97 -55 c97 -55 115 -75 81 -89 -10 -4 -53 -27
-95 -52 -69 -40 -78 -48 -78 -75 -1 -16 -2 -63 -3 -102 -1 -40 0 -73 3 -73 3
0 25 12 50 26 83 49 115 66 200 114 47 26 108 61 135 77 28 16 58 32 68 36 16
6 17 29 17 281 0 252 -1 274 -17 279 -21 7 -91 48 -148 87 -22 14 -68 42 -102
61 -35 18 -63 37 -63 41 0 5 -5 8 -11 8 -6 0 -38 16 -70 35 -33 19 -62 35 -65
35 -2 0 -3 -143 -2 -317z m305 131 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1
17 -2 13 -5z"/>
<path d="M2100 2980 c-36 -22 -83 -49 -105 -60 -46 -23 -92 -57 -85 -62 16
-13 150 -98 154 -98 12 0 128 -73 145 -92 11 -11 23 -18 28 -15 4 3 17 -1 27
-9 19 -13 19 -14 -4 -34 -27 -23 -19 -27 13 -5 20 13 26 13 51 0 38 -20 46
-12 46 43 l0 47 -100 55 -99 55 -3 108 -3 107 -65 -40z"/>
<path d="M2278 2578 c-21 -10 -38 -25 -38 -34 0 -12 6 -10 30 9 16 14 35 27
42 30 7 2 11 6 8 9 -3 2 -22 -4 -42 -14z"/>
<path d="M2188 2513 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2114 2486 c-30 -23 -13 -26 26 -5 26 14 30 19 14 19 -11 0 -29 -6
-40 -14z"/>
<path d="M1911 2413 c-1 -34 13 -52 23 -28 3 8 1 15 -4 15 -5 0 -11 10 -13 23
-3 16 -5 14 -6 -10z"/>
<path d="M2011 2424 c-20 -23 -9 -25 18 -3 13 11 17 19 10 19 -8 -1 -20 -8
-28 -16z"/>
<path d="M1497 1828 c-8 -18 -38 -86 -66 -150 -28 -64 -51 -119 -51 -122 0 -3
11 -6 24 -6 19 0 27 8 36 35 l12 35 82 0 82 0 13 -35 c10 -27 18 -35 37 -35
31 0 32 -6 -45 170 -52 118 -63 135 -85 138 -20 3 -28 -3 -39 -30z m71 -97
c12 -29 19 -57 16 -62 -3 -5 -28 -9 -55 -8 -46 2 -50 4 -45 24 11 44 48 117
55 109 4 -5 17 -33 29 -63z"/>
<path d="M1770 1705 l0 -157 90 4 c70 3 96 9 117 24 50 38 68 71 68 129 0 58
-18 91 -68 129 -21 15 -47 21 -117 24 l-90 4 0 -157z m201 76 c38 -46 37 -122
-2 -157 -22 -20 -140 -37 -150 -22 -7 10 -12 195 -5 206 2 4 35 5 71 2 55 -5
71 -10 86 -29z"/>
<path d="M2130 1705 l0 -155 111 0 c109 0 110 0 107 23 -3 21 -8 22 -83 23
l-80 2 0 42 0 43 70 1 c65 1 70 3 73 24 3 21 0 22 -72 22 l-76 0 0 40 c0 25 5
40 13 41 6 0 45 0 85 -1 71 -1 72 -1 72 24 l0 26 -110 0 -110 0 0 -155z"/>
<path d="M2450 1705 l0 -157 90 4 c70 3 96 9 117 24 50 38 68 71 68 129 0 58
-18 91 -69 129 -22 17 -46 22 -117 24 l-89 4 0 -157z m201 76 c38 -46 37 -122
-2 -157 -20 -18 -131 -36 -147 -24 -4 3 -7 50 -7 105 0 55 3 102 7 105 4 3 35
3 69 0 48 -5 66 -11 80 -29z"/>
<path d="M2906 1852 c-11 -18 -126 -285 -126 -293 0 -5 9 -9 21 -9 13 0 25 11
35 35 l14 35 83 0 83 0 13 -35 c9 -27 18 -35 37 -35 l24 0 -20 48 c-10 26 -40
95 -66 155 -40 91 -50 107 -70 107 -12 0 -25 -4 -28 -8z m56 -114 c14 -35 24
-66 21 -70 -7 -12 -103 -7 -103 5 0 17 44 127 51 127 3 0 17 -28 31 -62z"/>
<path d="M3124 1838 c3 -20 9 -23 50 -22 l46 0 0 -133 0 -133 25 0 25 0 0 129
0 130 48 3 c40 3 47 6 50 26 3 22 2 22 -122 22 -125 0 -126 0 -122 -22z"/>
<path d="M3536 1852 c-11 -18 -126 -285 -126 -293 0 -5 9 -9 21 -9 13 0 25 11
35 35 l14 35 84 0 83 0 9 -32 c8 -26 16 -34 37 -36 l27 -3 -19 48 c-11 26 -37
89 -59 138 -21 50 -42 98 -45 108 -6 17 -51 24 -61 9z m49 -99 c10 -27 22 -53
27 -60 17 -21 -2 -33 -53 -33 -32 0 -49 4 -49 12 0 18 44 128 51 128 3 0 14
-21 24 -47z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
